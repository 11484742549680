/*-----------------RESPONSIVE*/

$mob: 425px;
$tablet: 768px;
$laptop-small: 1024px;
$laptop: 1440px;


@mixin respond-to($media) {
  @if $media == mob {
    @media only screen and (max-width: $mob) { @content; }
  }
  @else if $media == medium-screens {
    @media only screen and (min-width: $mob + 1) and (max-width: $tablet) { @content; }
  }
  @else if $media == laptop-screens {
    @media only screen and (min-width: $laptop-small - 1) and (max-width: $laptop - 1) { @content; }
  }@else if $media == wide-screens {
    @media only screen and (min-width: $laptop) and (max-width: $laptop + 1){ @content; }
  }
}

.SendWrap {
    height: 1000px;
    margin-right: 50%;
}

.SendWrap img {
    //width: 100%;
}

.Main input {
    padding: 10px;
    margin: 2%;
}

.sendItems {
    height: 50px;
    width: 150px;
    background: #bbbbbb;
    cursor: pointer;
    text-align: -webkit-center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.Platforms {
    width: 70%;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 3%;   
    @include respond-to(mob) { 
        width: 95%;
    }
    @include respond-to(medium-screens) { 
      width: 95%;    
  }
  @include respond-to(laptop-screens) { 
    width: 85%;    
}
}

.PlatformsTop {
    display: flex;
    justify-content: space-around;
    width: 70%;
    margin: auto;
    @include respond-to(mob) { 
        width: 85%;
        flex-wrap: wrap;
        display: flex;
    }
}

.PlatformsTopTab {
    padding: 10px;
    border: 1px solid #e2e2e2;
    border-bottom: 0px solid #e2e2e2;
    border-radius: 5px;
    background: white;
    cursor: pointer;
    width: 25%;
    text-align: center;
    font-size: 18px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    @include respond-to(mob) { 
        padding: 4px;
        width: 100%;
    }

}

.PlatformsTopTab:hover {
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(0, 0, 0, 0.05);
}

.PlatformsItems  {

    width: 35%;
    margin-left: auto;
    margin-right: auto;
    @include respond-to(mob) { 
        width:55%;
    }

}

.PlatformsItems h4 {
    text-transform: uppercase;
}

.PlatformsItemsBtn {
    padding: 8px;
    border: 1px solid gainsboro;
    border-radius: 5px;
    width: 50%;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    cursor: pointer;
    @include respond-to(mob) { 
        width:80%;
    }

}

.PlatformsItemsBtn:hover {
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(0, 0, 0, 0.05);
}

.PlatformsItems img  {

    width: 80%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

}

.PlatformsBottom {
    display: flex;
    justify-content: space-around;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    background: white;
    padding: 20px;
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.08), inset 0 0 1px rgba(0, 0, 0, 0.05);
}

.PlatformsBottom:nth-child(2)  {
    padding: 10px;
}

.PlatformsBottom:hover {
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(0, 0, 0, 0.05);
}

.PlatformsList {

    width: 100%;

}

.PlatformsListH4 {
    text-align: center;
}

.PlatformsListWrap {

    display: flex;
    justify-content: space-around;
    text-align: center;    
    width: 90%;
    margin: auto;
    @include respond-to(mob) { 
        flex-wrap: wrap;
    }
    
}

.PlatformsListLink {

    display: flex;
    padding: 8px;
    border: 1px solid gainsboro;
    width: 20%;
    cursor: pointer;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    color: black;

    @include respond-to(mob) { 
        width: 60%;
    }
}

.PlatformsListLink h2 {

    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;

}

.PlatformsAttr {
    width: 25%;
    padding: 10px;
    background: rgb(231, 231, 231);
    border-radius: 5px;
}

.PlatformsAttr h4 {
    margin-bottom: 1%;
    @include respond-to(mob) { 
        text-align: center;
        font-size: 14px;
    }
}

.PlatformsAttrDiv {
    display: flex;
    justify-content: space-around;
    @include respond-to(mob) { 
        flex-wrap: wrap;
    }
}

.PlatformsMove {
    padding: 8px;
    text-align: center;
    border: 1px solid gainsboro;
    background: white;
    border-radius: 5px;
    width: 50%;
    margin: 1%;
    margin-top: 5%;
    margin-bottom: 5%;
    cursor: pointer;
    @include respond-to(mob) { 
        padding: 10px;
        font-size: 14px;
        width: 70%;
    }
}

.PlatformsMove:hover {
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(0, 0, 0, 0.05);
}


.Blocks {
   
    margin-top: 5%;
    padding: 15px;

}

.BlocksDiv {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    width: 25%;
    @include respond-to(mob) { 
        width: 100%;
    }
    @include respond-to(medium-screens) { 
      width: 55%;    
  }
  @include respond-to(laptop-screens) { 
    width: 35%;    
}
}

.Block {
    margin: 5px;
    background: white;
    padding: 13px;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.08), inset 0 0 1px rgba(0,0,0,.05);
    @include respond-to(mob) { 
        margin: 0px;
    }


}

.Block:hover {
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.18), inset 0 0 1px rgba(0,0,0,.05);
}

.Block a {
    color: black;
    text-decoration: none;
}

.Block img {
    width: 100%;
}

.Industry {
    margin-top: 7%;
    margin-bottom: 5%;
}

.IndustryWrap {

    @include respond-to(mob) { 
        width: 100%;
        overflow: auto;
    }

}

.ObukaCover, .OnamaCover {
    height: 600px;
    background-position: 90% 90%;

}

.TransportiCover{
    height: 400px;
    background-position: 90% 90%;
}

.IndustryTemplateCover {
    height: 450px;
    background-position: 90% 90%;
}

.IndustryTemplateDiv {
    padding: 20px;
    margin-top: 3%;
    margin-bottom: 3%;
}

.IndustryTemplateDiv div a {
    margin: 3%;
    background: #7cf455;
}

.IndustryButtons {
    display: flex;
    justify-content: center;
}

.IndustryButtons a {
    text-decoration: none;
    color: black;
    padding: 8px;
    border: 1px solid #dbd8d8;
    border-radius: 5px;
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.08), inset 0 0 1px rgba(0,0,0,.05);
}

.IndustryButtons a:hover {
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.18), inset 0 0 1px rgba(0,0,0,.05);
}

.Obuka {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 3%;
}

.ObukaWrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 3%;


}

.ObukaPerson {
    width: 15%;
    height: 80%;
    padding: 25px;
    background: white;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    margin-top: 2%;
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(0, 0, 0, 0.05);
        
    @include respond-to(mob) { 
        width: 55%;
    }
}

.ObukaPerson:hover {
    cursor: pointer;
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.18), inset 0 0 1px rgba(0, 0, 0, 0.05);
}

.ObukaPerson img {
    width: 100%;

}

.ObukaText {
    padding: 25px;
    width: 55%;
    @include respond-to(mob) { 
        width: 85%;
    }
    
}

.Industry h2 {
    text-align: center;
    margin-bottom: 2%;
    @include respond-to(mob) { 
        margin-bottom: 10%;
    }
}

.IndustryDiv {
    display: flex;
    justify-content: space-around;
    width: 70%;
    margin: auto;
    flex-wrap: wrap;
    @include respond-to(mob) { 
        width: 200%;  
    }
    @include respond-to(medium-screens) { 
      width: 60%;    
      margin-top: 5%;
  }
  @include respond-to(laptop-screens) { 
    width: 85%;    
}

}

.IndustryDiv a {

    text-align: center;
    color: black;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;

}

.IndustryCircle {

    height: 70px;
    width: 70px;
    border: 1px solid #aace3a;
    border-radius: 100%;
    padding: 5px;
    background: white;
    display: flex;
    justify-content: center;
    margin: auto;
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.15), inset 0 0 1px rgba(0,0,0,.05);
    transition: 0.4s;
    @include respond-to(mob) { 
        height: 50px;
        width: 50px;
            
    }
    @include respond-to(medium-screens) { 
        height: 80px;
        width: 80px;
  }
  @include respond-to(laptop-screens) { 

}

}

.IndustryCircle:hover {
    background: #fbff00;
}

.Industry img {

    height: 50px;
    width: 50px;
    margin: auto;

}

.react-multi-carousel-list {
    width: 60%;
    margin: auto;
    @include respond-to(mob) { 
        width: 85%;    
    }
    @include respond-to(medium-screens) { 
      width: 85%;    
  }
  @include respond-to(laptop-screens) { 
    width: 70%;    
}
}

.Whyus {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    margin: auto;
    @include respond-to(mob) { 
        width: 80%;
    }
    @include respond-to(medium-screens) { 
      width: 95%;    
  }
  @include respond-to(laptop-screens) { 
    width: 90%;    
}
}

.WhyusAbout {

    width: 70%;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 10%;
    @include respond-to(mob) { 
    }
    @include respond-to(medium-screens) { 
      width: 85%;    
  }
  @include respond-to(laptop-screens) { 
        
}

}

.WhyusAbout a {
    color: black;
    text-decoration: none;
}

.WhyusAbout:nth-child(1) .Btn {
    background: white;
    text-align: -webkit-center;
    border: 1px solid #cecece;
    padding: 10px;
    border-radius: 5px;
    width: 20%;
    margin: auto;
    cursor: pointer;
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.11), inset 0 0 1px rgba(0, 0, 0, 0.05);
    transition: 0.4s;
}

.WhyusAbout:nth-child(1) .Btn:hover, .WhyusAbout:nth-child(2) .Btn:hover  {
    background: #fbff00;
}

.WhyusAbout:nth-child(2) {
    display: flex;
    justify-content: space-around;
    width: 50%;
    margin: auto;
    margin-bottom: 12%;
    @include respond-to(mob) { 
    }
    @include respond-to(medium-screens) { 
      width: 65%;    
  }
  @include respond-to(laptop-screens) { 
        
}

}

.WhyusAbout:nth-child(2) .Btn {
    background: white;
    text-align: -webkit-center;
    border: 1px solid #cecece;
    padding: 10px;
    border-radius: 5px;
    width: 50%;
    margin-left: 45%;
    cursor: pointer;
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.11), inset 0 0 1px rgba(0, 0, 0, 0.05);
    transition: 0.4s;
}

.WhyusAbout:nth-child(2) p, .WhyusAbout:nth-child(2) h1 {
    text-align: right;
}

.WhyusAbout:nth-child(2) div:nth-child(1) {
    margin-right: 10%;
}

.WhyusAbout img {
    height: 200px;
    width: 200px;

}

.WhyusAbout h1 {
    text-align: center;
}

.Benefiti {
    display: flex;
    justify-content: space-between;
    width: 90%;
    flex-wrap: nowrap;
    margin: auto;
    @include respond-to(mob) { 
        flex-wrap: wrap;

    }
}

.Benefit {
    text-align: center;
    border-right: 1px solid #cccccc;
    padding: 10px;
    @include respond-to(mob) { 
        border-right: 0px solid #cccccc;

    }
}

.Benefit:nth-child(5){
    border-right: 0px solid;
}

.Benefit img {
    height: 50px;
    width: 50px;
}

.Najam {
    display: flex;
    flex-wrap: wrap;
}

.NajamSearch {

    width: 25%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    background: white;
    padding: 10px;
    border-radius: 8px;
    margin-top: 3%;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.08), inset 0 0 1px rgba(0, 0, 0, 0.05);
    @include respond-to(mob) { 
        width: 80%;
    }
    @include respond-to(medium-screens) { 
        
  }
  @include respond-to(laptop-screens) { 
    width: 30%; 
    
}

}

.NajamSearch:hover {
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.15), inset 0 0 1px rgba(0, 0, 0, 0.05);
}

.NajamSearch h3 {
    text-align: center;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 10px;
    font-size: 21px;
}

.Select {

    border: 1px solid #dcdcdc;
    border-radius: 5px;
    padding: 8px;
    outline: none;
    display: flex;
    justify-content: center;
    margin: auto;
}

.NajamSearchDiv {
    width: 90%;
    //border-right: 1px solid #dcdcdc;
    padding: 10px;
}

.NajamSearchDiv input {
    outline: none;
    border: 1px solid #dcdcdc;
    padding: 12px;
    width: 70%;
    border-radius: 5px;
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(0, 0, 0, 0.05);
}

.NajamSearchDiv:nth-child(4) {
    width: 35%;
    border-right: 0px ;
    width: 90%;
}

.NajamSearchFilterBox {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.NajamSearchDiv select {
    
    margin-top: 3%;
    text-transform: uppercase;
} 

.filterBtn {

    width: 25%;
    padding: 8px;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    text-align: center;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 13.5px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    @include respond-to(mob) { 
    }
    @include respond-to(medium-screens) { 
        
  }
  @include respond-to(laptop-screens) { 
    width: 35%; 
    padding: 10px;
}
}

.filterBtn:hover {
    background: #ebebeb;
}

.NajamItems {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 70%;
    margin: auto;
    margin-top: 2%;
    @include respond-to(mob) { 
        width: 100%;
    }
    @include respond-to(medium-screens) { 
        
  }
  @include respond-to(laptop-screens) { 
    width: 65%; 
    
}
}

.NajamItem {
    width: 28%;
    background: white;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    margin-top: 2%;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(0, 0, 0, 0.05);
    @include respond-to(mob) { 
        width:43%;
    }
    @include respond-to(medium-screens) { 
        
  }
  @include respond-to(laptop-screens) { 
    width: 38%; 
    
}
}

.NajamItem h3 {
    text-align: center;
}

.NajamItem:hover {
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.2), inset 0 0 1px rgba(0, 0, 0, 0.05);
}

.NajamItem img {
    width: 40%;
    height: 175px;
    display: flex;
    justify-content: center;
    margin: auto;
    @include respond-to(mob) { 
        width: 75%;
    }
}

.NajamItemSpec {
    width: 80%;
    margin: auto;
    margin-bottom: 3%;
    @include respond-to(mob) { 
        width: 95%;
    }
}

.NajamItemSpec table {

    width: 100%;
    margin: auto;
    border-spacing: 0;
    border-collapse: collapse;

}

.NajamItemSpec table td {
    padding: 5px;
    @include respond-to(mob) { 
        padding: 3px;
    }
}

.NajamItemSpec table tr {
    border-bottom: 1px solid #cecece;
    
}

.NajamItemSpec table tr:nth-last-child() {
    border-bottom: 0px solid #cecece;
    
}

.NajamItemSpec table tr:hover {
    background: 1px solid #e4e4e4;
    
}

.NajamItemSpec a {

    text-decoration: none;
    color: black;
    padding: 4px;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    margin-top: 2%;
}

.NajamItemSpec a:hover {
   box-shadow: 0px 0 8px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(0, 0, 0, 0.05);

}

.Kontakt {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    margin-bottom: 3%;
}

.Kontakt h1 {
    text-align: center;
}

.KontaktText {
    width: 45%;
    @include respond-to(mob) { 
        width:80%;
    }
}

.KontaktText, .KontaktBox {
    margin: auto;
    margin-top: 2%;
}

.KontaktBox a {
    text-decoration: none;
    color: black;
}

.KontaktBox {
    background: white;
    padding: 20px;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(0, 0, 0, 0.05);
}
.KontaktBox table {
    border-spacing: 0;
    border-collapse: collapse;
}

.KontaktBox table th {
    text-transform: uppercase;
}

.KontaktBox table td {
    padding: 15px;
    border-right: 1px solid #ebe9e9;
    @include respond-to(mob) { 
        padding: 10px;;
    }

}

.KontaktBox table td:nth-child(4) {
    border-right:0px solid;

}

.KontaktBox table tr:nth-child(even) {
    background: #efefef;
}

.Onama {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 3%;
}

.OnamaText, .TransportiText {
    padding: 20px;
    width: 70%;
    margin: auto;
    margin-top: 3%;

}

.OnamaBoxes {

    width: 80%;
    margin: auto;
    margin-top: 5%;
    @include respond-to(mob) { 
        width:90%;
    }
}

.Onama h1, .Transporti h1{
    text-align: center;
}

.OnamaBoxesDiv {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

}

.OnamaBox {
    width: 18%;
    height: 100%;
    background: white;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    margin-top: 2%;
    padding: 5px;
    cursor: pointer;
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(0, 0, 0, 0.05);
    @include respond-to(mob) { 
        width:70%;
    }
}

.OnamaBox:hover {
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.18), inset 0 0 1px rgba(0, 0, 0, 0.05);
}

.OnamaBox img {
    width: 100%;
}

.OnamaBox p img {
    width: 15%;
    vertical-align: middle;
}

.OnamaBox p a {
    text-decoration: none;
    color: black;
}

.loader,
.loader:before,
.loader:after {
  background: #fff200;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #fff200;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.ContactBox {
    height: 140px;
    width: 75px;
    background: rgb(216, 216, 216);
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: 200px;
    right: 0px;
    @include respond-to(mob) { 
        width:60px;
        height: 110px;
        top: 260px;
    }
}

.ContactBox img {
    width: 43px;
}

.ContactBox a {
    display: flex;
    justify-content: center;
    padding: 7px;
    border: 3px solid white;
    margin-bottom: 4%;
    background: #7cf455;
}

.ContactBox a:nth-child(2) {
    margin-bottom: 0%;
    background: #fbff00;
}

.Brands{

    padding: 8px;
    background: #eef3dd;

}

.BrandsWrap {
    display: flex;
    justify-content: center;
    width: 70%;
    margin: auto;
    flex-wrap: wrap;
}

.Brand {
    margin: auto;
}

.Brands a {
    margin: auto;
}

.Brands img {
    width: 90%;
}

.FormWrap {
    display: flex;
    justify-content: center;
    background: white;
    width: 50%;
    margin: auto;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(0, 0, 0, 0.05);
    margin-top: 5%;
    @include respond-to(mob) { 
        width: 85%;
    }
}

.FormWrap form {
    width: 100%;
    margin-left: 20%;
    margin-right: 20%;
    @include respond-to(mob) { 
        margin-left: 5%;
        margin-right: 5%;
    }
}

.formInput, .InputElement {
    width: 90%;
    outline: none;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.08), inset 0 0 1px rgba(0, 0, 0, 0.05);
    padding: 10px;
    margin: 2%;
}

.InputElement {
    height: 100px;
}

.FormWrap button {
    padding: 8px;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    cursor: pointer;
}

.FormWrap button:hover {
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(0, 0, 0, 0.05);
}


